.wrapper {
    width: 60%;
    margin: auto;
} 
.wrapper h1{
    text-align: start;
    padding: 0 0 0 50px;
    font-size: 56px;
}

@media (max-width: 1100px){
    .wrapper {
        width: 90%;
        margin: auto;
    } 
    .wrapper h1{
        text-align: start;
        padding: 0 0 0 10px;
        font-size: 40px;
    }
}