@media(min-width: 1100px){


.Input {
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
}
.Label{
    text-align: start;
    margin: 0;
    display: block;
    font-weight: 100;
    font-size: 16px;
    margin: 5px 0;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Invalid {
    border: 1px solid red;
    background-color: salmon;
}

.Input textarea{
    height: 100px;
}
.Input textarea{
    height: 100px;
}
}

@media(max-width: 1099px){

.Input {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
}
.Label{
    text-align: start;
    margin: 0;
    display: block;
    font-weight: 100;
    font-size: 16px;
    margin: 5px 0;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Invalid {
    border: 1px solid red;
    background-color: salmon;
}

.Input textarea{
    height: 100px;
}
.Input textarea{
    height: 100px;
}
}