.wrapper{
    display: grid;
    grid-template-columns: 45% 55%;
}
.wrapper h2{
    font-weight: 500;
    font-size: 18px;
    font-style: inherit;
    padding: 0 10px 0 0;
    text-align: end;
}

.wrapper .number{
    background-color: rgb(71, 71, 71);
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 7px 0px;
    margin: 10px 0;
    font-weight: bold;
    border-radius: 20px;
    max-height: 25px;
    max-width: 150px;
}
.wrapper .number a{
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 700;
}