.infoContainer{
    background-color: var(--color-6);
    margin: 10px 0;
    border-radius: 20px;
}
.infoContainer h3{
        text-align: start;
        margin: 0;
        padding: 10px 0 5px 35px;
        font-weight: 100;
        font-size: 16px;
}
.infoContainer input{
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 90%;
    margin: 5px auto;
    box-sizing: border-box;
}

@media (max-width: 1100px){
    .infoContainer{
        background-color: var(--color-6);
        margin: 10px 0;
        border-radius: 20px;
    }
    .infoContainer h3{
        text-align: start;
        margin: 0;
        padding: 10px 0 5px 20px;
        font-weight: 100;
        font-size: 16px;
    }
    .infoContainer input{
        outline: none;
        border: 1px solid #ccc;
        background-color: white;
        font: inherit;
        padding: 6px 10px;
        display: block;
        width: 90%;
        margin: 5px auto;
        box-sizing: border-box;
    }
}