.popup{
    position: fixed;
    left: calc(100vw * 0.5 - 150px);
    width: 300px;
    display: grid;
    grid-template-rows: auto auto;
    z-index: 1000;
    top: 350px;
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
}
.popup .message_section{
    height: auto;
    /* background-color: rgb(255, 71, 71); */
    background-color: rgb(231, 231, 231);
    border-radius: 10px 10px 0 0;
} 
.message_section h2{
    font-size: 18px;
    font-weight: 500;
    padding: 20px 20px;
    margin: 0;
    
}

.popup span{
    font-size: 18px;
    padding: 20px 0;
    width: 150px;
    margin: auto;
}

