.Navbar{
    position: fixed;
    overflow: hidden;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: var(--color-1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 1000;
}

.Navbar nav{
    height: 10%;
}

@media (max-width: 1100px){
    .DesktopOnly{
        display:none;
    }
    .Navbar {
        height: 60px;
        z-index: 1000;
    }
}