@media (min-width: 1100px){

    .desktop{
        display: grid;
        grid-template-columns: auto auto;
        
    }
    .calendarWrapper .calendar{
        height: auto;
        background-color: rgba(255, 255, 255, 0.2);
        /*  display: grid;
        grid-template-columns: 15% auto; */
        
        margin: 0px 0px 50px;
        width: auto;
        border-radius: 6px;
    }
    
    .calendarWrapper.calendar .times{
        width: auto;
        max-width: 200px;
        margin: 0 3px 0 0;
    }
    .calendarWrapper .calendar .times .timelabel{
        height: 100px;
        background-color: var(--color-4);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendar .times .timelabel h4{
        margin: 0;
        padding: 40px 10px;
        font-size: 16px;
        font-weight: 700;
    }
    .calendarWrapper .calendar .times .header{
        height: auto;
        background-color: var(--color-2);

    }
    
    .calendarWrapper .calendarInner {
        height: auto;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
    }
    
    .calendarWrapper .calendarInner .day{
        width: auto;
        margin: 0 3px 0 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendarInner .day .header{
        height: 100px;
        /*  background-color: rgb(92, 92, 92); */
        background-color: var(--color-1);
        opacity: 0.95;
        /* border-radius: 6px 6px 0 0;  */
      width: 100%;
      clip-path: polygon(20% 0%, 0 13%, 0 48%, 0% 80%, 5% 100%, 31% 89%, 70% 100%, 100% 87%, 100% 53%, 99% 9%, 80% 0%, 51% 9%);
      transform: scale(1.1);
      margin-top: 5px;
    }

    .calendarWrapper .calendarInner .day .currentDay{
        height: 100px;
        background-color:  var(--color-2);
        transform: scale(1.2);
        color: white;
    }
    .calendarWrapper .calendarInner .day .header h2{
        margin: 0;
        padding: 8px 0 0 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 1000;
        color: rgb(255, 255, 255);
        
    }
    @import url('https://fonts.googleapis.com/css2?family=Chango&family=Comfortaa:wght@700&family=Fjalla+One&family=Rubik:wght@500&display=swap');
    
    .calendarWrapper .calendarInner .day .program{
        height: 100px;
        position: relative;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
        overflow-y:auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

    }
    .calendarWrapper .calendarInner .day .program::-webkit-scrollbar {
        display: none;
      }
    .calendarWrapper .calendarInner .day .program h5{
        margin: 0;
        padding: 2px;
    }
    .calendarWrapper .calendarInner .day .onehour{
        height: 50px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 3px 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendarInner .day .twohours{
        height: 101px;
        background-color: rgba(0, 0, 0, 0.05);
        margin: 3px 0 3px 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendarInner .day .threehours{
        height: 153px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendarInner .day .fourhours{
        height: 203px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendarInner .day .fivehours{
        height: 256px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendarInner .day .sixhours{
        height: 309px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarWrapper .calendarInner .day .program h3{
        margin: 0;
        padding: 2px 2px 2px 2px;
        font-size: 15px;
        font-weight: 400;
    }
    @media (max-width: 1400px){
        .calendarWrapper .calendarInner .day .program h3{
            margin: 0;
            padding: 2px 2px 2px 2px;
            font-size: 14px;
            font-weight: 400;
        }
    }
    
    .calendarWrapper .calendarInner .day .program:hover{
        cursor: pointer;
        background-color: rgba(5, 5, 5, 0.2);
        transform: scale(1.05);
        
    }
}



@media (max-width: 1099px){
    .mobile{
        display: grid;
        grid-template-columns: 40% 40%;
        grid-gap: 20%;
    }


    .calendarWrapper{
        display: none;
    }
    .calendar{
        height: auto;
        background-color: rgba(255, 255, 255, 0.5);
        margin: 0px 0px 50px;
        width: auto;
        border-radius: 3px;
    }
    
    .calendar .times{
        width: auto;
        margin: 0 2px 0 0;
    }

    .calendar .times .header{
        height: auto;
        background-color: var(--color-2);
    }
    
    .calendarInner {
        height: auto;
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
    }
    
    .calendarInner .day{
        width: auto;
        margin: 0 2px 0 0;
        box-sizing: border-box;
    }
    .calendarInner .day .header{
        height: 75px;
        background-color: var(--color-1);
        opacity: 0.95;
        /* border-radius: 6px 6px 0 0;  */
  
     /*  clip-path: polygon(20% 0%, 0 13%, 0 48%, 0% 80%, 5% 100%, 31% 89%, 70% 100%, 100% 87%, 100% 53%, 99% 9%, 80% 0%, 51% 9%); */
      /* transform: scale(1.2); */
    
    }
    .calendarInner .day .currentDay{
        height: 75px;
        background-color: var(--color-2);
        transform: scale(1.1);
        color: white;
    }
    .calendarInner .day .header h2{
        font-size: 16px;
        margin: 0;
        padding: 8px 0 0 0 ;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 800;
        color: white;
    }
    
    .calendarInner .day .program{
        height: 100px;
        position: relative;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 2px 0 0 0;
        box-sizing: border-box;
    }

    .calendarInner .day .program h5{
        margin: 0;
        padding: 2px;
        font-size: 13px;
    }
    .calendarInner .day .onehour{
        height: 50px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarInner .day .twohours{
        height: 101px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 3px 0;
        box-sizing: border-box;
    }
    .calendarInner .day .threehours{
        height: 153px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarInner .calendarInner .day .fourhours{
        height: 203px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarInner .day .fivehours{
        height: 256px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarInner .day .sixhours{
        height: 309px;
        background-color: rgba(5, 5, 5, 0.05);
        margin: 3px 0 0 0;
        box-sizing: border-box;
    }
    .calendarInner .day .program h3{
        margin: 0;
        padding: 5px 5px 0 5px;
        font-size: 12px;
        font-weight: 400;
    }
    .calendarInner .day .program:hover{
        cursor: pointer;
        background-color: rgba(5, 5, 5, 0.3);
        
    }
}
@media (max-width: 500px){
    .calendarInner .day .program h5{
        margin: 0;
        padding: 2px;
        font-size: 12px;
    }
    .calendarInner .day .program h3{
        margin: 0;
        padding: 5px 0px 0 0px;
        font-size: 10px;
        font-weight: 400;
    }
}