@keyframes animation {
    0% { 
      transform:  scale(0);
    }
    100% {
      transform:  scale(1);
    }
  }

@media (max-width: 1100px){

    .popup{
        position: fixed;
        z-index: 1000;
        top: 200px;
        left: 10%;
        margin: auto;
        width:  80%;
        height: auto;
        text-align: center;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255);
        display: grid;
        grid-template-rows: auto auto;
        animation: animation; 
        border-radius: 5px;
    }
    .popup .header{
  
        background-color: var(--color-1); 
   
        border-radius: 0 0;
      /*   clip-path: polygon(20% 0%, 0 13%, 0 48%, 0% 80%, 5% 100%, 31% 89%, 70% 100%, 100% 87%, 100% 53%, 99% 9%, 80% 0%, 51% 9%);
    */

        width: 100%;
    
        left: 10px;
    }
    .popup .header h2{
        margin: 0;
        padding-top: 5px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 1000;
        font-size: 30px;
        color: white;
    }

    .popup .body {
        background-color: rgb(255, 255, 255);
        margin-top: 4px;
        display: grid;
        grid-template-columns: 33% 67%;
        grid-template-rows: auto auto auto auto;
        border-radius: 0;
    }

    .popup .body h3{
        margin: 0;
        font-weight: 100;
        font-size: 14px;
        text-align: start;
        padding: 10px 0 0 15px;
        height: auto;
        min-height: 40px;
        width: 100%;
    }
    .popup .body h4{
        margin: 0;
        font-weight: 100;
        font-size: 18px;
        text-align: start;
        padding: 10px 20px 10px 10px;
        height: auto;
        min-height: 40px;
        font-size: 16px;
    }
    .popup .delete{
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .dropdown{
        position: absolute;
        right: 20px;
        top: 40px;
        opacity: 1
    }
    
    
    .dropdown .dropdownElement{
        width: 200px;
        height: auto;
        display: grid;
        border: 1px solid rgb(179, 179, 179);
        border-radius: 2px;
        position: absolute;
        right: 0;
        z-index: 1000;
        background-color: white;
        box-shadow: 5px 5px 5px -5px rgb(58, 58, 58);
        border-radius: 20px;
    }
    .dropdown .dropdownElement button{
        outline: none;
        border: none;
        width: auto;
        height: auto;
        background-color: none;
    }
    
}
    
@media (min-width: 1100px){
    .popup{
        position: fixed;
        z-index: 1000;
        top: 200px;
        left: calc(100vw * 0.5 - 250px);
        margin: auto;
        width:  500px;
        height: auto;
        text-align: center;
        box-sizing: border-box;
        background-color: rgba(255, 255, 255);
        display: grid;
        grid-template-rows: auto auto;
        animation: animation 0.3s; 
        border-radius: 0px;
       
        
  
    }
    .popup .header{
        background-color: var(--color-1); 
   
        border-radius: 0 0;
       /*  clip-path: polygon(20% 0%, 0 13%, 0 48%, 0% 80%, 5% 100%, 31% 89%, 70% 100%, 100% 87%, 100% 53%, 99% 9%, 80% 0%, 51% 9%); */
        height: auto;
        
    }
    .popup .currentDay{
        background-color: rgb(37, 37, 37);
        color: white;
    
    }
    .popup .header h2{
        margin: 0;
        padding: 10px 0;
        font-size: 30px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 1000;
        color: white;
    }

    .popup .body {
        background-color: rgb(255, 255, 255);
        margin-top: 4px;
        display: grid;
        grid-template-columns: 30% 70%;
        grid-template-rows: auto auto auto auto;
    }

    .popup .body h3{
        margin: 0;
        text-align: start;
        font-size: 16px;
        padding: 10px 0 0 20px;
        height: auto;
        min-height: 40px;
        width: auto;
        font-style: inherit;
        font-weight: 100;
        
    }
    .popup .body h4{
        margin: 0;
        font-weight: 100;
        font-size: 18px;
        text-align: start;
        padding: 10px 20px 10px 20px;
        height: auto;
        min-height: 40px;
    }
    .popup .delete{
        position: absolute;
        top: 0;
        right: 0;
    }

    .dropdown{
        position: absolute;
        right: 20px;
        top: 40px;
        opacity: 1
    }
    
    
    .dropdown .dropdownElement{
        width: 200px;
        height: auto;
        display: grid;
        border: 1px solid rgb(179, 179, 179);
        border-radius: 2px;
        position: absolute;
        right: 0;
        z-index: 1000;
        background-color: white;
        box-shadow: 0 2px 20px -5px rgb(121, 121, 121);
        border-radius: 20px;
    }
    .dropdown .dropdownElement button{
        outline: none;
        border: none;
        width: auto;
        height: auto;
        background-color: none;
    }
    
}