.settings{
    width: 90%;
    margin: auto;
    border-top: solid 1px rgb(0, 0, 0);
    border-bottom: solid 1px rgb(0, 0, 0);
    display: grid;
    grid-template-columns: 90% 10%;
    margin: 20px auto;
   
}
.settings h3{
    font-weight: 400;
    font-size: 16px;
    text-align: start;
    padding-left: 20px;
}