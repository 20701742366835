.NavigationItems{
    top: 0;  
    margin: 0;
    padding: 0;
    margin-top: 50px;
    list-style: none;
    flex-flow: column;
    display: flex;
    align-items: center;
    border: none;
}

.NavigationItems .logo {
    color: white;
    padding: 5px;
}

.NavigationItems .logo:hover {
    color: var(--color-2);
    transform: scale(1.1);
}



@media(max-width: 1100px){
    .NavigationItems {
        padding-bottom: 80px;
    }

    .NavigationItems a img{
        display: none;
    }

    .NavigationItems .logo {
        color: var(--color-1);
        position: static;
        display: inline-block;
        padding: 0 10px;
    }

    .SocialBar {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
        background: white; /* Optional: match your page bg */
        padding: 10px;
        border-radius: 30px;
        z-index: 999;
    }

    .NavigationItems .logo:hover {
        color: var(--color-2);
        transform: scale(1.1);
    }

}
@media (min-width: 1100px) {
    .NavigationItems{
        margin-top: 0;
        height: 50px;
        width: 100vw;
        flex-flow: row;
        left: 0;
        top: 0;  
        background-color: var(--color-1);
        z-index: 1000;
    }
    .NavigationItems a img{
        padding: 0 5px;
    }
    .NavigationItems .last_element{
        margin-right: 10px;
    }
}